// Typography

// $font--provider: "Google Fonts";
// $font--import: "IBM+Plex+Sans:500,600|Heebo:400,500,700";

// // The font set
// $font--family: (
//   heading: '"IBM Plex Sans", serif',
//   base: '"Heebo", sans-serif',
//   code: 'Monaco, Consolas, "Andale Mono", "DejaVu Sans Mono", monospace',
//   pre: '"Courier 10 Pitch", Courier, monospace',
// );

$font--provider: "Google Fonts";
$font--import: "Roboto:400,500,700";

// The font set
$font--family: (
  heading: "Roboto, sans-serif;",
  base: '"Roboto", sans-serif',
  code: 'Monaco, Consolas, "Andale Mono", "DejaVu Sans Mono", monospace',
  pre: '"Courier 10 Pitch", Courier, monospace',
);

// The typographic scale
$font--scale: (
  // key 		// font-size, line-height, kerning
  alpha: (44px, 54px, null),
  beta: (36px, 46px, null),
  gamma: (32px, 42px, null),
  delta: (24px, 34px, -0.1px),
  epsilon: (20px, 30px, -0.1px),
  zeta: (18px, 28px, -0.1px),
  eta: (16px, 24px, -0.1px),
  theta: (14px, 22px, null)
);

// Base elements: font size
$font--size: (
  // ↓ Mobile 	// key in $font--scale
  h1: "beta",
  h2: "gamma",
  h3: "delta",
  h4: "delta",
  base: "epsilon",
  sm: "zeta",
  xs: "eta",
  xxs: "theta",
  bquote: "epsilon",
  figcap: "theta",
  code: "eta",
  pre: "eta",
  table: "eta",
  // ↓ Desktop (set to null if font size won't change on desktop)
  h1-desktop: "alpha",
  h2-desktop: "beta",
  h3-desktop: "gamma",
  h4-desktop: null,
  base-desktop: null,
  sm-desktop: null,
  xs-desktop: null,
  xxs-desktop: null,
  bquote-desktop: null,
  figcap-desktop: null,
  code-desktop: null,
  pre-desktop: null,
  table-desktop: null
);

// Base elements: font weight
$font--weight: (
  heading: 600,
  base: 400,
  strong: 700,
  bquote: 600,
  figcap: null,
);
