@include media(">medium") {
  .has-animations {
    .timeline-item {
      &:nth-child(even) {
        .timeline-item-content {
          &.reveal-from-side {
            transform: translateX($scroll-reveal--from-left-amount);
          }
        }
      }

      &:nth-child(odd) {
        .timeline-item-content {
          &.reveal-from-side {
            transform: translateX($scroll-reveal--from-right-amount);
          }
        }
      }
    }

    &.is-loaded {
      .timeline-item {
        .timeline-item-content {
          &.is-revealed {
            transform: translate(0);
          }
        }
      }
    }
  }
}

.timeline-item-header {
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: 0.5em;
}

.timeline-item-content {
    font-weight: normal;
    font-size: 1rem;
    line-height: 1.15rem;
    // text-align: left;
}
