.container,
.container-sm,
.container-xs {
	width: 100%;
	margin: 0 auto;
	padding-left: $container--padding__mobile;
	padding-right: $container--padding__mobile;

	@include media( '>small' ) {
		padding-left: $container--padding__desktop;
		padding-right: $container--padding__desktop;
	}
}

.container-animation {
	width: 60%;
	margin: 0 auto ;
	padding: 2% 0 0 2%;
	border-radius: 9px;
	 color: #17c297;
	border-style: solid;

	padding-left: $container--padding__mobile;
	padding-right: $container--padding__mobile;

	box-shadow: 0 15px 25px 0 rgba(23, 194, 151, 0.15),0 5px 10px 0 rgba(34, 42, 63,.05);

	@include media( '<large' ) {
		width: 90%;
	}

	@include media( '<small' ) {
		width: 95%;
	}
}

.container {
	max-width: $container--width + ( $container--padding__desktop * 2 );
}

.container-sm {
	max-width: $container--width-sm + ( $container--padding__desktop * 2 );
}

.container-xs {
	max-width: $container--width-xs + ( $container--padding__desktop * 2 );
}

[class*=container] {

	[class*=container] {
		padding-left: 0;
		padding-right: 0;
	}

    .container-sm {
		max-width: $container--width-sm;
    }

	.container-xs {
		max-width: $container--width-xs;
	}
}
