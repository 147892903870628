// Colors

// The color palette
$palette: (
  light: (
    1: #dde2f4,
    2: #99a1ba,
    3: #70778e,
  ),
  dark: (
    // 1: #161822,
    1: #222a3f,
    2: #2b2f40,
    3: #3f455a,
  ),
  primary: (
    1: #4a61dd,
    2: #7587e4,
    3: #2742cc,
    4: #ccd4ff,
    5: #fff,
  ),
  secondary: (
    1: #2ea7e2,
    2: #60bce8,
    3: #1c8bc2,
    4: #b8dff1,
  ),
  alert: (
    error: #ee5860,
    warning: #e8a658,
    success: #17c297,
  ),
);

// Base typography: color
$color: (
  heading: get-color(light, 1),
  base: get-color(light, 2),
  high-contrast: get-color(light, 1),
  mid-contrast: get-color(light, 2),
  low-contrast: get-color(light, 3),
  primary: get-color(primary, 1),
  secondary: get-color(secondary, 1),
  error: get-color(alert, error),
  warning: get-color(alert, warning),
  success: get-color(alert, success),
  // ↓ Inverted colors
  heading-inverse: get-color(dark, 1),
  base-inverse: get-color(light, 3),
  high-contrast-inverse: get-color(dark, 1),
  mid-contrast-inverse: get-color(dark, 2),
  low-contrast-inverse: get-color(light, 3),
);

// Borders and dividers: color
$border--color: (
  divider: get-color(dark, 2),
  divider-inverse: get-color(light, 1),
);

// Icons: fill color
$icon--color: (
  hamburger: get-color(light, 2),
  // hamburger icon
  accordion: get-color(primary, 1),
  // accordion icon
  social: get-color(primary, 1),
  // social icons
  social-hover: get-color(primary, 2),
  // social icons (:hover)
  modal: get-color(light, 3),
  // modal icon
  modal-hover: get-color(light, 2),
  // modal icon (:hover)
  // ↓ Inverted colors
  hamburger-inverse: get-color(dark, 2),
  // hamburger icon
  accordion-inverse: get-color(primary, 1),
  // accordion icon
  success-inverse: get-color(alert, 3),
  social-inverse: null,
  // social icons
  social-hover-inverse: null // social icons (:hover),,,,
);

// Misc elements: background color
$bg--color: (
  body: get-color(dark, 1),
  body-shadow: 0 20px 48px rgba(get-color(dark, 1), 0.8),
  // body shadow when a boxed layout is used (set to null if don't want a shadow)
  body-outer: lighten(get-color(dark, 1), 2%),
  // outer bg color when a boxed layout is used
  bg-color: lighten(get-color(light, 1), 9%),
  // .has-bg-color helper class
  shadow: 0 24px 64px rgba(get-color(dark, 1), 0.64),
  // .has-shadow helper class
  code: darken(get-color(dark, 1), 3%),
  code-inverse: darken(get-color(dark, 1), 3%),
);
